// src/context/UserActionContext.js
import React, { createContext, useState, useContext } from 'react';

const UserActionContext = createContext();

export const UserActionProvider = ({ children }) => {
  const [hasTakenAction, setHasTakenAction] = useState(false);

  return (
    <UserActionContext.Provider value={{ hasTakenAction, setHasTakenAction }}>
      {children}
    </UserActionContext.Provider>
  );
};

export const useUserAction = () => useContext(UserActionContext);