import './src/styles/global.scss'
import { createRoot } from "react-dom/client"
import React from 'react'
import { UserActionProvider } from './src/utils/context/actions'

export const replaceHydrateFunction = () => {
  return (element, container) => {
    createRoot(container).render(element)
  }
}

export const wrapRootElement = ({ element }) => (
  <UserActionProvider>{element}</UserActionProvider>
);